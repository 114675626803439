import { Col as C, Grid as G } from '@cuidardigital/commons/components/Grid/grid'
import styled from 'styled-components'
import { Span } from '../../superSetCommons'

export const Col = styled(C)``
export const Grid = styled(G)``
interface IWrapper {
	height?: string | number
	mt?: string | number
	isAppointment?: boolean
}

export const ComponentWrapper = styled.div`
	display: flex;
	flex-direction: row;
`

export const NoContentWrapper = styled.div``

export const OldAppointmentTitle = styled(Span)`
	white-space: inherit;
`

export const SidebarTitle = styled(Span)`
	font-size: 20px;
	line-height: 1.1;
	color: #333333;
`

export const SidebarContainer = styled.div`
	/* margin-top: -5.3%; */
	/* min-width: 18%; */
	overflow: auto;
	max-height: 1500px;
	-webkit-box-shadow: 4px -3px 5px 0px rgba(217, 217, 217, 1);
	-moz-box-shadow: 4px -3px 5px 0px rgba(217, 217, 217, 1);
	box-shadow: 4px -3px 5px 0px rgba(217, 217, 217, 1);

	display: block;

	@media (max-width: 1030px) {
		width: 500px;
	}
	@media (max-width: 780px) {
		margin-top: -1vh;
		width: 500px;
	}

	@media (max-width: 480px) {
		display: ${props => (props.mobileActive ? 'block' : 'none')};
		width: 100vw;
		min-width: 100vw;
		margin: 0;
	}
`

export const ContentWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;

	@media (max-width: 480px) {
		display: ${props => (props.mobileActive ? 'block' : 'none')};
		width: 100vw;
		margin: 0;
	}
`

export const Header = styled.div<IWrapper>`
	/* padding: 20px; */
	margin-top: 25px;
	/* width: 64%; */
	display: flex;
	align-items: center;
	justify-content: ${props => (props.isAppointment ? 'flex-end' : 'space-between')};
	margin-left: 30px;

	@media (max-width: 480px) {
		margin-left: 10px;
		flex-flow: column-reverse;
	}
`

export const AppointmentsContainer = styled.div`
	margin-left: 30px;
	display: block;
	@media (max-width: 480px) {
		margin-left: 10px;
		padding-bottom: 40px;
	}
`

export const AppointmentContainer = styled.div`
	margin: 5px 10px;
`

export const Appointments = styled.div`
	display: flex;
	flex-wrap: wrap;
`

export const AppointmentTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin: 18px 10px;
`

export const SpanContainer = styled.div`
	word-wrap: break-word;
	max-width: 80%;
	font-size: 32px;
	font-weight: bold;
	line-height: 1.1;
	color: #333333;
	font-family: 'CentraleSans-Bold';
	@media (max-width: 850px) {
		font-size: 20px;
	}
	@media (max-width: 480px) {
		max-width: 100%;
		margin-top: 10px;
	}
`

export const ButtonContainer = styled.div<IWrapper>`
	margin-left: ${props => (props.isAppointment ? '18px' : '0px')};
	@media (max-width: 480px) {
		width: 100%;
		text-align: right;
		margin-left: 0;
	}
`

export const Button = styled.button`
	min-width: 120px;
	color: #fff;
	outline: none;
	font-weight: bold;
	font-family: 'CentraleSans-Bold';
	padding: 14px 20px;
	font-size: 13px;
	border: none;
	margin-right: 50px;
	border-radius: 4px;
	background-color: ${props => (props.disabled ? props.theme.colors.button_disabled : props.theme.colors.primary)};
	border: 1px solid
		${props => (props.disabled ? props.theme.colors.button_disabled_border : props.theme.colors.primary_hover)};
	&:hover {
		box-shadow: 2px 2px -10px #00000026;
		background: ${props => (props.disabled ? props.theme.colors.button_disabled : props.theme.colors.primary_hover)};
	}
	@media (max-width: 850px) {
		min-width: 155px;
		font-size: 12px;
	}

	@media (max-width: 480px) {
		margin-right: 10px;
	}
`

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import SupportIcon from '@cuidardigital/commons/assets/icons/support'
import __filter__ from 'lodash/filter'

import { Search } from '@cuidardigital/commons/components/Inputs'
import { Loader } from '@cuidardigital/commons/components/Loader'

import IAppointment from '@cuidardigital/commons/types/IAppointment'
import IConsultationClosed from '@cuidardigital/commons/types/IConsultationClosed'
import IModalMessage from '@cuidardigital/commons/types/IModalMessage'
import IModalProd from '@cuidardigital/commons/types/IModalProp'
import { Appointment, ConsultationClosed, Support as SupportCard } from '../../Cards'

import NewAppointment from '../../../components/newAppointment'
import { store } from '../../../stores/app'
import TemplatePage from '../../../templates/Page'
import { initUserSession } from '../../../utils/session'
import CancelAppointment from '../../cancelAppointment'
import { Icon, SupportButton, SupportText } from '../../Dashboard/styles'
import {
	AppointmentContainer,
	Appointments,
	AppointmentsContainer,
	AppointmentTitleContainer,
	Button,
	ButtonContainer,
	ComponentWrapper,
	ContentWrapper,
	Header,
	SidebarContainer,
	SidebarTitle,
	SpanContainer
} from './styles'

import Support from '../../Support'

import { TOUR_STEP_ID } from '@cuidardigital/commons/configs/tour'
import {
	dateToISOWithTime,
	formatDate,
	isToday,
	orderDate
} from '@cuidardigital/commons/utils/date'
import { graphql, useStaticQuery } from 'gatsby'
import { Span } from '../../superSetCommons'

import { mockAdvisories, mockAppointments } from '@cuidardigital/commons/mocks/tour.json'

interface IDashboard {
	height?: string | number
	title?: string
	paragraphy?: string
	buttonText?: string
	image?: { src: string }
	goToForm?: () => void
	step: string
}

interface INewAppointment {
	setShowNewAppointment: any
	setLoading: any
	setMessageToShow: any
	setShowMessage: any
}

interface ICancelAppointment {
	setShowCancelAppointment: any
	appointmentToCancel: string
	setLoading: any
	setMessageToShow: any
	setShowMessage: any
	state: any
}

interface IConsultation {
	cpf: string
	name: string
	email: string
}

const isoStringToDateString = (str: string) => {
	const parsedDate = new Date(str)
	const pad = '00'
	const dayStr = parsedDate.getDate().toString()
	const day = pad.substring(0, pad.length - dayStr.length) + dayStr.toString()
	const monthStr = (parsedDate.getMonth() + 1).toString()
	const month = pad.substring(0, pad.length - monthStr.length) + monthStr.toString()
	const year = parsedDate.getFullYear()
	return `${day}/${month}/${year}`
}

const isoStringToHourString = (str: string) => {
	const parsedDate = new Date(str)
	return parsedDate.toTimeString().substring(0, 5)
}

const mapAppointmentClosedResult = (newList: any) =>
	orderDate(
		newList.filter((item: any) => {
			const date = new Date()
			if (date.getMinutes() >= 30) {
				date.setMinutes(date.getMinutes() - 30)
			} else {
				date.setHours(date.getHours() - 1)
				date.setMinutes(date.getMinutes() + 30)
			}

			const appointmentDate = new Date(item.date)
			return (appointmentDate < date && item.status === 'CONFIRMED') || item.status === 'FINISHED'
		}),
		'desc'
	)
		// .sort((a: any, b: any) => {
		// 	const dateA = new Date(a.date)
		// 	const dateB = new Date(b.date)
		// 	return dateA > dateB ? -1 : dateA < dateB ? 1 : 0
		// })
		.map((item: any) => ({
			...item.patient,
			hour: isoStringToHourString(item.date),
			date: isoStringToDateString(item.date),
			doctorEmail: item.doctor.email,
			link: item.status === 'CONFIRMED' ? item.doctorLink : '',
			consultationId: item.id
		}))

const mapAppointmentResult = (newList: any) =>
	orderDate(
		newList.filter((item: any) => {
			const date = new Date()
			if (date.getMinutes() >= 30) {
				date.setMinutes(date.getMinutes() - 30)
			} else {
				date.setHours(date.getHours() - 1)
				date.setMinutes(date.getMinutes() + 30)
			}

			const appointmentDate = new Date(item.date)
			return appointmentDate >= date && (item.status === 'CONFIRMED' || item.status === 'CREATED')
		}),
		'asc'
	)
		// .sort((a: any, b: any) => {
		// 	const dateA = new Date(a.date)
		// 	const dateB = new Date(b.date)
		// 	return dateA < dateB ? -1 : dateA > dateB ? 1 : 0
		// })
		.map((item: any) => ({
			...item.patient,
			doctorEmail: item.doctor.email,
			consultationId: item.id,
			status: item.status,
			hour: isoStringToHourString(item.date),
			date: isoStringToDateString(item.date),
			link: item.status === 'CONFIRMED' ? item.doctorLink : '',
			patientLink: item.patientLink,
			medicalEncounterId: item.medicalEncounterId,
			patientPhone: item.patientPhone
		}))

const limit = 1000

const Dashboard: React.FC<IDashboard> = ({ step }) => {
	// @ts-ignore
	const { state, dispatch } = useContext(store)
	initUserSession(state, dispatch)
	const [] = useState(false)
	const [showNewAppointment, setShowNewAppointment] = useState(false)
	const [showCancelAppointment, setShowCancelAppointment] = useState(false)
	const [appointmentToCancel] = useState('')
	const [appointments, setAppointments] = useState([])
	const [consultationClosed, setConsultationClosed] = useState([])
	const [filteredConsultationClosed, setFilteredConsultationClosed] = useState<IConsultation[]>([])
	const [loading, setLoading] = useState(false)
	const [, setShowMessage] = useState(false)
	const [messageToShow, setMessageToShow] = useState<IModalMessage>()
	const [newList] = useState(mockAppointments)
	const [supportList] = useState([])
	const [page, setPage] = useState(0)
	const [showSupport, setShowSupport] = useState(false)
	// States para mobild
	const [mobileActive] = useState('')

	const data = useStaticQuery(query)
	const {
		consultationsCarriedOut,
		paragraph,
		buttonNewAppointment
	} = data.saudeDigital.metadata.dashboard
	const { button_support } = data.saudeDigital.metadata.advice

	useEffect(() => {
		setFilteredConsultationClosed(filterClosedConsultation(''))
	}, [consultationClosed])
	useEffect(() => {
		if (newList.length >= limit * (page + 1)) {
			setPage(page + 1)
		} else {
			const listAppointments = mapAppointmentResult(newList)
			const listWithOriginalDataTime = orderDate(
				[...listAppointments, ...supportList].map(item => ({
					...item,
					date: dateToISOWithTime(item.date, item.hour)
				})),
				'asc'
			)
			setAppointments(
				listWithOriginalDataTime.map(item => ({
					...item,
					date: isoStringToDateString(item.date),
					hour: isoStringToHourString(item.date)
				}))
			)
			setConsultationClosed(mapAppointmentClosedResult(newList))
		}
	}, [newList, supportList])

	useEffect(() => {
		if (step === TOUR_STEP_ID.OPEN_SUPPORT) setShowSupport(true)
		else if (step === TOUR_STEP_ID.SUPPORT_CARD || step === TOUR_STEP_ID.CONSULTATION_CLOSED)
			setShowSupport(false)
	}, [step])

	const filterClosedConsultation = (searchable: string): IConsultation[] =>
		__filter__(
			consultationClosed,
			(consultation: IConsultation) =>
				consultation.cpf.includes(searchable) ||
				consultation.name.toLowerCase().includes(searchable.toLowerCase())
		)

	const appointmentsByDay: IAppointment[][] = useMemo(
		() =>
			Object.values(
				appointments.reduce((obj: { [key: string]: IAppointment[] }, item: IAppointment) => {
					obj[item.date] = (obj[item.date] || []).concat([item])
					return obj
				}, {})
			),
		[appointments]
	)

	const nope = (e: React.ChangeEvent<HTMLInputElement>) => {}

	const newAppointmentParam: INewAppointment = {
		setShowNewAppointment,
		setLoading,
		setMessageToShow,
		setShowMessage
	}

	const cancelAppointmentParam: ICancelAppointment = {
		setShowCancelAppointment,
		appointmentToCancel,
		setLoading,
		setMessageToShow,
		setShowMessage,
		state
	}

	const modalMessageParam: IModalProd = {
		setShowModal: setShowMessage,
		title: messageToShow?.title || '',
		message: messageToShow?.message || '',
		buttonText: messageToShow?.buttonText || ''
	}

	return (
		<>
			{loading && <Loader />}
			<TemplatePage>
				{showCancelAppointment && <CancelAppointment {...cancelAppointmentParam} />}
				{showNewAppointment && <NewAppointment {...newAppointmentParam} />}
				<ComponentWrapper>
					<SidebarContainer
						mobileActive={mobileActive === 'closed'}
						className={TOUR_STEP_ID.CONSULTATION_CLOSED}
					>
						<div style={{ padding: 20, backgroundColor: 'white' }}>
							<SidebarTitle bold={true} fontSize='24px/32px'>
								{consultationsCarriedOut}
							</SidebarTitle>

							<Search
								id='input-search-closed-consultation'
								inputPadding='14px'
								inputBackground='#f7f7f7'
								onChange={nope}
								onKeyPress={nope}
								onSearch={nope}
								label='Pesquise'
							/>
						</div>
						{filteredConsultationClosed.map((item: IConsultationClosed, index: number) => (
							// tslint:disable-next-line: jsx-key
							<ConsultationClosed isDoctor={state.session.isDoctor} onShowInfo={nope} {...item} />
						))}
					</SidebarContainer>
					<ContentWrapper mobileActive={mobileActive !== 'closed'}>
						<Header isAppointment={appointmentsByDay.length > 0}>
							{appointmentsByDay.length === 0 && <SpanContainer>{paragraph}</SpanContainer>}
							<ButtonContainer isAppointment={appointmentsByDay.length > 0}>
								<Button p='0px 10px' className={TOUR_STEP_ID.BUTTON_NEW_CONSULTATION}>
									{buttonNewAppointment}
								</Button>
							</ButtonContainer>
						</Header>
						<AppointmentsContainer>
							{appointmentsByDay.map((item: IAppointment[]) => (
								<>
									<AppointmentTitleContainer>
										<Span wrap bold={true} fontSize='32px/40px' fontsm='18px'>
											{isToday(item[0].date)
												? 'Suas consultas para hoje'
												: formatDate(item[0].date)}
										</Span>
										<Span fontSize='16px/24px'>(Total de {item.length} pacientes)</Span>
									</AppointmentTitleContainer>
									<Appointments>
										{item.map((appointment: IAppointment, index: number) => (
											// tslint:disable-next-line: jsx-key
											<AppointmentContainer
												className={index === 0 ? TOUR_STEP_ID.CONSULTATION_OPENED : ''}
											>
												{!appointment.isSupport ? (
													<Appointment
														onCopy={nope}
														onStart={nope}
														onCancel={nope}
														onNewUrl={nope}
														{...appointment}
													/>
												) : (
													<SupportCard onStart={nope} onCancel={nope} {...appointment} />
												)}
											</AppointmentContainer>
										))}
									</Appointments>
								</>
							))}
						</AppointmentsContainer>
					</ContentWrapper>
				</ComponentWrapper>
				{showSupport && <Support />}
				<SupportButton id='cuidar-support-button' showSupport={showSupport}>
					<SupportIcon />
					<SupportText>{button_support}</SupportText>
				</SupportButton>
			</TemplatePage>
		</>
	)
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				dashboard {
					consultationsCarriedOut: consultas_realizadas
					paragraph: nao_possui_consultas_confirmadas
					buttonNewAppointment: botao_novo_agendamento
				}
				advice: assessoria {
					button_support: botao_suporte
				}
			}
		}
	}
`

export default Dashboard

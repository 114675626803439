import React, { useContext, useEffect, useState } from 'react'

import Dashboard from '../../../components/Tour/Dashboard'

import { store, TYPES } from '../../../stores/app'

import { TOUR_STEP_ID } from '@cuidardigital/commons/configs/tour'
import { tourOptions, tourSteps } from '@cuidardigital/commons/configs/tour'
import { putUpdateDoctorOptins } from '@cuidardigital/commons/services/certification.valid.services'
import { navigate } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import ExamResults from '../../../components/Tour/ExamResults'
import { HOME, TOUR_PATIENT_DETAIL } from '../../../routes'
const { ShepherdTourContext, ShepherdTour } =
	// tslint:disable-next-line: no-var-requires
	typeof window !== 'undefined' ? require('react-shepherd') : {}

const ContainerShepherd = () => {
	const data = useStaticQuery(query)
	const { state } = useContext(store)

	return ShepherdTour ? (
		<ShepherdTour
			steps={tourSteps('bradesco')(data, state.session.isDoctor)}
			tourOptions={tourOptions}
		>
			<Main />
		</ShepherdTour>
	) : (
		''
	)
}
const Main = () => {
	// @ts-ignore
	const { state, dispatch } = useContext(store)
	const tour = useContext(ShepherdTourContext)

	const { session, doctorCurrent } = state
	const [step, setStep] = useState('')
	const [patientView, setPatientView] = useState(null)

	useEffect(() => {
		if (!state.session.cpf) {
			navigate(HOME)
			window.localStorage.removeItem('TOURDONE')
			cancelTour()
		} else
			setTimeout(() => {
				handleTour()
			}, 500)
	}, [])

	const handleTourChange = current => {
		setStep(current.step.id)
		switch (current.step.id) {
			// STEP PARA PODER ABRIR O MENU E ASSIM PODER DESTACAR PELA LIB
			case TOUR_STEP_ID.OPEN_MENU:
				window.openMenu(true, true)
				if (
					current.previous.id === TOUR_STEP_ID.MENU_SECRETARY ||
					current.previous.id === TOUR_STEP_ID.MENU_PROFILE
				) {
					setTimeout(() => {
						window.openMenu(false, false)
						tour.show(TOUR_STEP_ID.BUTTON_NEW_CONSULTATION, false)
					}, 500)
				} else
					setTimeout(() => {
						if (session.isDoctor) tour.show(TOUR_STEP_ID.MENU_SECRETARY, true)
						else tour.show(TOUR_STEP_ID.MENU_PROFILE, true)
					}, 1500)
				break
			// STEP PARA PODER NAVEGAR ATÉ A TELA DO PACIENTE
			case TOUR_STEP_ID.OPEN_PATIENT_RESULTS:
				const { mockAppointments } = require('@cuidardigital/commons/mocks/tour.json')
				window.openMenu(false, false)
				dispatch({
					type: TYPES.SET_PATIENT,
					payload: {
						consultationId: mockAppointments[1].id,
						cpf: mockAppointments[1].patient.cpf,
						name: mockAppointments[1].patient.name,
						email: mockAppointments[1].patient.email,
						phone: mockAppointments[1].patient.phone,
						birthDay: mockAppointments[1].patient.birthDay,
						doctorEmail: mockAppointments[1].doctor.email,
						link: mockAppointments[1].patientLink,
						newConsultation: false,
						touring: true
					}
				})
				// navigate(TOUR_PATIENT_DETAIL)
				setPatientView({
					consultationId: mockAppointments[1].id,
					cpf: mockAppointments[1].patient.cpf,
					name: mockAppointments[1].patient.name,
					email: mockAppointments[1].patient.email,
					phone: mockAppointments[1].patient.phone,
					birthDay: mockAppointments[1].patient.birthDay,
					doctorEmail: mockAppointments[1].doctor.email,
					link: mockAppointments[1].patientLink,
					newConsultation: false,
					touring: true
				})
				setTimeout(() => {
					tour.show(TOUR_STEP_ID.PATIENT_BUTTON_PRONTMED, true)
				}, 1000)
				break
			// STEP PARA ABRIR O SUPORTE
			case TOUR_STEP_ID.OPEN_SUPPORT:
				window.openMenu(false, false)
				if (current.previous.id === TOUR_STEP_ID.SUPPORT_TECH) {
					setTimeout(() => {
						tour.show(TOUR_STEP_ID.CONSULTATION_CLOSED, false)
					}, 500)
				} else
					setTimeout(() => {
						tour.show(TOUR_STEP_ID.SUPPORT_TECH, true)
					}, 1500)
				break
		}
	}

	const handleTour = () => {
		tour.start()
		tour.on('complete', uptadeOptinTourDone)
		tour.on('show', handleTourChange)
		tour.on('cancel', cancelTour)
	}

	const cancelTour = () => {
		window.localStorage.removeItem('showValidAd')
		navigate(HOME)
	}
	const uptadeOptinTourDone = () => {
		cancelTour()
		if (doctorCurrent.optins) putUpdateDoctorOptins(doctorCurrent.optins, 'TOURFEITO')
	}

	if (patientView) {
		return <ExamResults {...patientView} />
	}
	return <>{session.accessToken && <Dashboard step={step} />}</>
}

const query = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				tour_platform: tour_plataforma {
					button_next: botao_proximo
					button_back: botao_voltar
					patient_description: paciente_descricao

					schedules: agendamentos {
						title: titulo
						paragraph: paragrafo
					}

					new_appointment: novo_agendamento {
						title: titulo
						paragraph: paragrafo
					}

					acquire_certificate: adquirir_certificado {
						title: titulo
						paragraph: paragrafo
					}

					secretary: cadastrar_uma_secretarioa {
						title: titulo
						paragraph: paragrafo
					}

					doubts: duvidas {
						title: titulo
						paragraph: paragrafo
					}

					consultations: consultas_realizadas {
						title: titulo
						paragraph: paragrafo
					}

					medicalRecord: prontuario_eletronico {
						title: titulo
						paragraph: paragrafo
					}

					exams_results: resultados_de_exames {
						title: titulo
						paragraph: paragrafo
					}

					prescriptions: prescricoes_emitidas {
						title: titulo
						paragraph: paragrafo
					}
					tour_complete: tour_completo {
						title: titulo
						paragraph: paragrafo
						tour_complete_button_start: botao_comecar
					}
					support: suporte {
						title: titulo
						paragraph: paragrafo
					}
					advisoryDadone: assessoria_danone {
						title: titulo
						paragraph: paragrafo
					}
					menuProfile: secretaria_perfil {
						title: titulo
						paragraph: paragrafo
					}
					secretaryDoctorList: secretaria_lista_medicos {
						title: titulo
						paragraph: paragrafo
					}
					advisoryCard: assessoria_aberta {
						title: titulo
						paragraph: paragrafo
					}
					financial: financeiro {
						title: titulo
						paragraph: paragrafo
					}
				}
			}
		}
	}
`

export default ContainerShepherd
